
import { Component, Vue, Inject, Prop } from "vue-property-decorator";
import _ from "lodash";
import { DependencyContainer } from "tsyringe";
import { PROVIDER, SERVICES as S } from "@/config/literals";

import UpdateUi from "@/components/Core/Update/UpdateUI.vue";

import { SentenceInfos } from "@/domain";

import { ISentenceService } from "@/services";
import { toNumber } from "@/tools";

@Component({
  components: {
    UpdateUi,
  },
  data: () => {
    return {
      toNumber,
    };
  },
})
export default class SentenceUpdate extends Vue {
  @Inject(PROVIDER)
  container!: DependencyContainer;

  @Prop({ required: true })
  item!: SentenceInfos;

  get sentenceService() {
    return this.container.resolve<ISentenceService>(S.SENTENCE_SERVICE);
  }

  loading: boolean = false;
  number: number | null = null;
  method: string | null = null;
  target: string | null = null;
  sentenceValue: string | null = null;
  emptySentenceRule: boolean | string = true;

  mounted() {
    this.number = this.item.number;
    this.method = this.item.method;
    this.target = this.item.target;
    this.sentenceValue = this.item.sentenceValue;
  }

  async update() {
    this.triggerValidation();
    try {
      if (this.emptySentenceRule == true) {
        this.loading = true;
        const sentenceUpdated = await this.sentenceService.update(
          this.item.id,
          {
            number: (this.number != undefined && this.number) || null,
            method: this.method,
            target: this.target,
            sentenceValue: this.sentenceValue,
          }
        );
        this.$emit("update", sentenceUpdated);
      }
    } finally {
      this.loading = false;
    }
  }

  updateNumber(number: number) {
    this.number = number;
    this.triggerValidation();
  }

  updateMethod(method: string) {
    this.method = method;
    this.triggerValidation();
  }

  updateTarget(target: string) {
    this.target = target;
    this.triggerValidation();
  }

  updateValue(value: string) {
    this.sentenceValue = value;
    this.triggerValidation();
  }

  triggerValidation() {
    this.emptySentenceRule =
      this.method || this.target || this.sentenceValue || this.number != null
        ? true
        : "Un des champs doit être renseigné";
  }
}
